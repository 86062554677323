import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CopyToClipboardBannerStyles from './CopyToClipboardBanner.styles';
import Copy from 'react-feather/dist/icons/copy';
import X from 'react-feather/dist/icons/x';
import { copyAsCSV, getAssetIdFromPath } from '../../../utils/utils';
import { CONSTANTS } from '../../../constants/constants';
import NRRemediateIcon from '../../../NRIcons/NRRemediateIcon';
import React, { useContext, useEffect, useState } from 'react';
import { ReportTabsContext } from '../../../pages/NRReportTabs/NRReportTabsContextWrapper';
import { RemediationContext } from '../../NRRemediation/NRRemediationContext';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridContext } from '../../NRReactGrid/NRReactGridContext';
import { useSnackbar } from 'notistack';
import FileDownloadComponent from '../../NRCertTable/CertTableCustomComponents/FileDownloadComponent';
import NRGroupIcon from '../../../NRIcons/NRGroupIcon';
import NRRemoveGroupsIcon from '../../../NRIcons/NRRemoveGroupsIcon';
import { AssetGroupContext } from '../../AssetGroup/assetGroupContextWrapper';
import useHasPermission from '../../Permissions/useHasPermission';
import { useLocation } from 'react-router-dom';
import NRSelectAll from '../NRSelectAll/NRSelectAll';
const { ITEM_SELECTED, ITEMS_SELECTED, TOOLTIP_COPY, ADD_TO_GROUPS, REMOVE_FROM_GROUPS, TOOLTIP_UNSELECT, TOOLTIP_REMEDIATE, TOOLTIP_DOWNLOAD } =
  CONSTANTS.TABLE.COPY_TO_CLIPBOARD_BANNER;
const { VULNERABILITIES: VULNERABILITIES_TAB, FILE_SYSTEM: FILE_SYSTEM_TAB } = CONSTANTS.REPORT.TABS;

const useStyles = makeStyles(CopyToClipboardBannerStyles); // add to constants

const addToGroupsValidPaths = ['/assets'];

function CopyToClipboardBanner({ selectedItems, setSelection, rows, totalCount, showSelectAllItems, selectAllFetchConfig }) {
  const { currentTab } = useContext(ReportTabsContext);
  const { setOpenRemediationModal, setCVEs } = useContext(RemediationContext);
  const { setShouldClearSelection } = useContext(GridContext);
  const { enqueueSnackbar } = useSnackbar();
  const [filesToDownload, setFilesToDownload] = useState();
  const [disableDownload, setDisableDownload] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const theme = useTheme();
  const { setSelectedAssets, setShowManageGroupsAddBulkModal, setShowManageGroupsRemoveAllModal } = useContext(AssetGroupContext);
  const canWriteGroups = useHasPermission({ permission: CONSTANTS.PERMISSIONS.WRITE_GROUPS });
  const location = useLocation();
  const pathname = location.pathname;
  const showAddToGroupOptions = addToGroupsValidPaths.includes(pathname);

  const classes = useStyles();

  const handleRemediationClick = () => {
    let cvesToRemediate = !!allItems.length ? allItems : selectedItems.map(item => rows[item]);
    setCVEs(cvesToRemediate);
    setOpenRemediationModal(true);
  };

  const handleAddToGroupsClick = () => {
    let assetsToAdd = !!allItems.length ? allItems : rows.filter((e, index) => selectedItems.includes(index));
    setSelectedAssets(assetsToAdd);
    setShowManageGroupsAddBulkModal(true);
  };

  let assetId = getAssetIdFromPath();

  useEffect(() => {
    return () => setFilesToDownload(null);
  }, []);

  useEffect(() => {
    if (currentTab === FILE_SYSTEM_TAB) {
      handleBulkDownload();
    }
  }, [selectedItems]);

  const handleBulkDownload = () => {
    let toReturn = selectedItems.map(item => ({ path: rows[item]?.path }));
    setDisableDownload(selectedItems.some(item => !rows[item]?.canDownload));
    setFilesToDownload(toReturn);
  };

  return (
    <div className={classes.mainContainer}>
      {!allItems.length && (
        <div className={classes.title}>
          {selectedItems.length + ' '}
          {selectedItems.length > 1 ? ITEMS_SELECTED : ITEM_SELECTED}
        </div>
      )}
      {!!allItems.length && (
        <div className={classes.title}>
          {allItems.length > 1 ? 'All ' : ''}
          {allItems.length + ' '}
          {allItems.length > 1 ? ITEMS_SELECTED.toLowerCase() : ITEM_SELECTED.toLowerCase()}
        </div>
      )}
      {!!selectAllFetchConfig && showSelectAllItems && (
        <NRSelectAll allItems={allItems} setAllItems={setAllItems} itemsCount={totalCount} {...selectAllFetchConfig} />
      )}
      <div className={classes.actions}>
        {currentTab === VULNERABILITIES_TAB && (
          <>
            <Tooltip title={TOOLTIP_REMEDIATE}>
              <IconButton aria-label="Remediate items" component="span" color="inherit" size="small" onClick={handleRemediationClick}>
                <NRRemediateIcon color={theme.palette.secondary.contrastText} />
                <Typography variant={'subtitle2'} color={theme.palette.secondary.contrastText} sx={{ ml: 1, mr: 1 }}>
                  Remediate
                </Typography>
              </IconButton>
            </Tooltip>
          </>
        )}
        {currentTab === FILE_SYSTEM_TAB && (
          <>
            <FileDownloadComponent
              componentId={assetId}
              fileList={filesToDownload}
              cursorPointer={true}
              tooltipTitle={TOOLTIP_DOWNLOAD}
              color={theme.palette.secondary.contrastText}
              showButtonComponent={true}
              afterDownloadAction={() => setShouldClearSelection(true)}
              disabled={disableDownload}
            />
          </>
        )}
        {!!canWriteGroups && showAddToGroupOptions && (
          <>
            <Tooltip title={ADD_TO_GROUPS}>
              <IconButton
                aria-label="Add to Groups"
                component="span"
                color="inherit"
                size="small"
                onClick={() => handleAddToGroupsClick()}
                classes={{ colorInherit: classes.actions }}
              >
                <NRGroupIcon />
                <Typography variant={'subtitle2'} color={theme.palette.secondary.contrastText} sx={{ ml: 1, mr: 1 }}>
                  Add to Groups
                </Typography>
              </IconButton>
            </Tooltip>
            <Tooltip title={REMOVE_FROM_GROUPS}>
              <IconButton
                aria-label="Remove from Groups"
                component="span"
                color="inherit"
                size="small"
                onClick={() => {
                  setSelectedAssets(rows.filter((e, index) => selectedItems.includes(index)));
                  setShowManageGroupsRemoveAllModal(true);
                }}
                classes={{ colorInherit: classes.actions }}
              >
                <NRRemoveGroupsIcon />
                <Typography variant={'subtitle2'} color={theme.palette.secondary.contrastText} sx={{ ml: 1, mr: 1 }}>
                  Remove from Groups
                </Typography>
              </IconButton>
            </Tooltip>
          </>
        )}
        <Tooltip title={TOOLTIP_COPY}>
          <IconButton
            aria-label="copy to csv"
            component="span"
            color="inherit"
            size="small"
            disabled={!!allItems.length}
            onClick={() => {
              copyAsCSV(selectedItems.map(item => rows[item]));
              enqueueSnackbar(CONSTANTS.COPIED_TO_CLIPBOARD_MSG, { variant: 'success' });
              setShouldClearSelection(true);
            }}
            classes={{ colorInherit: classes.actions }}
          >
            <Copy />
            <Typography
              variant={'subtitle2'}
              color={!!allItems.length ? theme.palette.text.disabled : theme.palette.secondary.contrastText}
              sx={{ ml: 1, mr: 1 }}
            >
              Copy to CSV
            </Typography>
          </IconButton>
        </Tooltip>
        <Tooltip title={TOOLTIP_UNSELECT}>
          <IconButton
            aria-label="unselect"
            component="span"
            color="inherit"
            size="small"
            onClick={() => {
              setSelection([]);
              setAllItems([]);
            }}
            classes={{ colorInherit: classes.actions }}
          >
            <X />
            <Typography variant={'subtitle2'} color={theme.palette.secondary.contrastText} sx={{ ml: 1 }}>
              Cancel
            </Typography>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}
export default CopyToClipboardBanner;
