import { gql } from '@apollo/client';

export const QL_UPDATE_ASSET = gql`
  mutation updateAsset($args: UpdateAssetInput) {
    asset {
      update(args: $args) {
        status
        product
        version
        vendor
        name
        componentId
        type
        updatedAt
      }
    }
  }
`;

//v3
export const GET_ASSET = gql`
  query asset($args: AssetInput) {
    asset(args: $args) {
      id
      status
      startTime
      endTime
      updatedAt
      createdAt
      earliestSnapshotEndTime
      name
      vendor
      product
      version
      assetCpe
      componentId
      type
      sha256
      hasRemediation
      assetGroups {
        id
        name
        description
      }
      assetGroupCount
      risk {
        score
        category
      }
      analytic {
        vulnerability {
          critical
          high
          medium
          low
        }
        exploit {
          threatActor
          ransomware
          botnet
          inTheWild
          knownAttacks
        }
        components {
          application
          framework
          library
          container
          device
          kernel
          package
          kernelModule
          os
        }
        misconfigurations {
          passed
          failed
          notApplicable
        }
        cryptography {
          publicKey
          privateKey
          certificate
        }
        binaries
        credentials {
          credential
          hash
          crackedCred
          crackedHash
        }
        files
      }
    }
  }
`;

//v3
export const GET_ASSET_GROUP_ANALYTIC = gql`
  query assetGroupAnalytics($args: AssetGroupAnalyticsInput!) {
    assetGroupAnalytics(args: $args) {
      totalComponents
      exploits
      misconfigurations
      invalidCertificates
      crackedCredentials
    }
  }
`;

export const GET_LATEST_SNAPSHOT = gql`
  query latestSnapshot($args: AssetInput) {
    latestSnapshot(args: $args) {
      status
      snapshotId
    }
  }
`;

export const GET_ASSET_UPLOAD = gql`
  query assetUpload($args: AssetUploadInput) {
    assetUpload(args: $args) {
      uploadId
      assetId
      uploaded
    }
  }
`;

export const GET_ASSETS_NAMES = gql`
  query assetsNamesRelay($args: AssetsRelayInput!) {
    assetsRelay(args: $args) {
      edges {
        node {
          id
          name
          status
        }
      }
    }
  }
`;

export const GET_ASSETS = gql`
  query assetsRelay($args: AssetsRelayInput!) {
    assetsRelay(args: $args) {
      edges {
        cursor
        node {
          id
          name
          componentId
          vendor
          assetGroupCount
          assetGroups {
            id
          }
          product
          version
          createdAt
          updatedAt
          endTime
          type
          sha256
          risk {
            score
          }
          status
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_ASSETS_OVERVIEW = gql`
  query assetsOverview($args: AssetOverviewInput!) {
    assetsOverview(args: $args) {
      edges {
        cursor
        node {
          composedAssetId
          name
          vendor
          product
          version
          submitDatetime
          operatingSystem
          operatingSystemKernelVersion
          type
          risk {
            rawScore
            score
            category
          }
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_ASSETS_STATUSES = gql`
  query assetsStatuses($args: AssetsRelayInput!) {
    assetsRelay(args: $args) {
      edges {
        node {
          componentId
          updatedAt
          status
          endTime
        }
      }
    }
  }
`;

export const GET_ALL_ASSETS_IDS = gql`
  query allAssetsIds($args: AssetsRelayInput!) {
    assetsRelay(args: $args) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_COMPONENTS_COUNT = gql`
  query listComponent($args: ListComponentInput) {
    listComponent(args: $args) {
      pageInfo {
        totalSize
      }
    }
  }
`;

export const LIST_FILES = gql`
  query listFiles($componentId: String!, $filesystemId: ID, $fileInput: FilesInput) {
    asset(args: { assetId: $componentId }) {
      filesystems(args: { filesystemId: $filesystemId }) {
        files(args: $fileInput) {
          filesList {
            file {
              digest {
                md5
                sha1
                sha256
              }
              path
              createdAt
              updatedAt
              size
              mimeType
              permissions
              hasChildren
            }
            fileId
          }
          pageInfo {
            nextPageToken
            totalSize
          }
        }
      }
    }
  }
`;

export const QL_SUBMIT_ASSET = gql`
  mutation submitAsset($fileName: String!, $args: SubmitAssetInput) {
    asset {
      submit(fileName: $fileName, args: $args) {
        uploadUrl
        uploadId
      }
    }
  }
`;

export const GET_SIGNED_URL_DOWNLOAD_SBOM = gql`
  query sbom($args: SBOMDownloadInput) {
    download {
      sbom(args: $args) {
        operationId
      }
    }
  }
`;

export const GET_OPERATION = gql`
  query operation($args: OperationInput) {
    operation(args: $args) {
      operationId
      done
      error
      signedUrl {
        url
        signedHeaders {
          key
          value
        }
      }
    }
  }
`;

export const GET_SIGNED_URL_DOWNLOAD_FILE = gql`
  query file($args: FileDownloadInput) {
    download {
      file(args: $args) {
        operationId
      }
    }
  }
`;

export const GET_SIGNED_URL_DOWNLOAD_FILE_LIST = gql`
  query fileList($args: FileListDownloadInput!) {
    download {
      fileList(args: $args) {
        operationId
      }
    }
  }
`;

export const GET_SIGNED_URL_DOWNLOAD_EXTRACTED_COMPONENT = gql`
  query extractedFirmware($args: ExtractedFirmwareDownloadInput) {
    download {
      extractedFirmware(args: $args) {
        operationId
      }
    }
  }
`;

export const GET_SIGNED_URL_DOWNLOAD_COMPONENT = gql`
  query firmware($args: FirmwareDownloadInput) {
    download {
      firmware(args: $args) {
        operationId
      }
    }
  }
`;

export const SIFT_SEARCH = gql`
  query sift($args: SiftInput!) {
    sift {
      files(args: $args) {
        hasMore
        files {
          sha256
          path
          matches {
            file
            ordinal
            similarity
            text
          }
          assets {
            componentId
            name
            product
            packages
          }
        }
      }
    }
  }
`;

export const SIFT_METRICS = gql`
  query siftCount {
    sift {
      count {
        assetCount
        fileCount
        pythonFileCount
        scriptFileCount
        textFileCount
      }
    }
  }
`;
